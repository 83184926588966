@import url('http://fonts.cdnfonts.com/css/callie-chalk-font');
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

// https://fontmeme.com/chalk-fonts/
// https://www.canva.com/colors/color-palettes/

.coding {
  width:100%;
  text-align:center;

}
.coding img {
  box-shadow: 0px 0px 10px #000;

}
.resume-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding-top: 2%;

}

.contact-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100vw;
}

.contact-wrap p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 1em;
  padding:1em;
  &:hover {
    cursor: pointer;
    background-color: rgba(34, 34, 34, 0.178);
    transition: all 0.25s ease-in-out;
  }
}

.contact-wrap p span {
display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width:4em;
}
.contact-wrap a {
  text-decoration: none;
  color: #222;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  margin: 0 2rem;
  font-weight: bold;
      font-family: 'Special Elite', cursive;


}
.space {
  height:20vh;
  width:100%;
}
.wrapper {
  display: flex;

  justify-content: space-around;
  /* flex-flow: wrap; */
  flex-wrap: wrap;
  height: 130%;
  gap: 2px;

}

.wrapper .project {
  background: rgba(231, 231, 231, 0.712);
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  margin-bottom: 2em;

  border-radius: 5px;
  width: 25em;
  height: 25em;
  color: #eee;
  padding: 1em;
}

.wrapper .project span {
  font-size: .8em;
  color: #222;
}


.project1 {
  background-image: url('/project1.png');


  background-size: 25em;
  background-repeat: no-repeat;
  width: 25em;
  height: 15em;
}

.project2 {
  background-image: url('/project2.png');


  background-size: 25em;
  background-repeat: no-repeat;
  width: 25em;
  height: 15em;
}

.project3 {
  background-image: url('/project3.png');


  background-size: 25em;
  background-repeat: no-repeat;
  width: 25em;
  height: 15em;
}

.project4 {
  background-image: url('/project4.jpg');


  background-size: 25em;
  background-repeat: no-repeat;
  width: 25em;
  height: 15em;
}

.whiteboard {}

.contents {
  font-family: 'Special Elite', cursive;
  padding: 2em 4em;
  // margin-bottom: 10em;
  text-align: justify;
  color: #222;
  overflow-y: auto;
  height: 100%;
}

.card {
  background-color: rgba(255, 255, 255, 0.212);
  border-radius: 0.5em;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  padding: 2em;
  margin: 1em;
}


.frame {
  position: absolute;
  top: 12%;
  left: 20%;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;

  background-image: url('/firas.jpeg');
border-top: 15px solid #eee;
border-bottom: 55px solid #eee;
border-left: 10px solid #eee;
border-right: 10px solid #eee;
  box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.3);

transform: rotate(-15deg);



}

.sticky {
  position: absolute;

  background-size: 90%;
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
  background-image: url('/sticky.png');
}

.sticky1 {
  top: 2%;
  left: 20%;
  transform: rotate(-25deg);
  color:rgba(9, 37, 131, 0.877)

}


.sticky2 {
  top: 43%;
  left: 37%;
  transform: rotate(15deg);
  color:rgba(131, 9, 35, 0.877)

}

.sticky3 {
  top: 10%;
  left: 50%;
  transform: rotate(5deg);
  color:rgba(28, 29, 32, 0.877)

}

.sticky4 {
  top: 40%;
  left: 4%;
  transform: rotate(-14deg);
  color:rgba(16, 70, 9, 0.877)

}

.sticky5 {
  top: 40%;
  right: 1%;
  transform: rotate(1deg);
  color:rgba(131, 17, 160, 0.877)

}




.sticky div {
  margin-left: 4em;
  margin-top: 7em;
font-family: 'Gochi Hand', cursive;
font-size: 1em;


}
.sticky div p {
  margin:0;
font-size: 1.5em !important;


}

.sticky div li {
font-size: 1em !important;


}


.notebook {
  position: absolute;
  bottom: -10%;
  left: 5%;
  background-size: 80%;
  background-repeat: no-repeat;
  width: 400px;
  height: 550px;

  background-image: url('/notebook.png');


transform: rotate(-25deg);



}

.notebook div {
  position: relative;
  margin-left: 6em;
  margin-top: 4em;
font-family: 'Gochi Hand', cursive;
font-size: .8em;
color:rgba(9, 37, 131, 0.877)

}

.cup {
  position: absolute;
  top: 5%;
  right: 5%;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 200px;
  height: 200px;

  background-image: url('/cup.png');


transform: rotate(15deg);



}


.mac {
  position: absolute;
  bottom: -15%;
  right: 0%;
  background-size: 80%;
  background-repeat: no-repeat;
  width: 800px;
  height: 600px;

  background-image: url('/mac.png');


transform: rotate(15deg);



}

@media (min-width: 1480px) {
  .menu-font {
    font-family: 'Special Elite', cursive;
    font-size: 1.5em;
    font-weight: 500;
    color: #222;
    text-decoration: underline;
    line-height: .7em;
    padding-top: .8rem;
    text-align: center;
  }

  .menu-size {
    height: 3vh;
    width: 12vw;
    top: -4vh;
    border-radius: 15px 15px 0 0;

  }

  .tab-class {
    top: 15vh;
    margin: 0 2%;
    width: 95%;
    height: 85vh;
    border-radius: 5px;
  }

  .contents {

    font-size: 1em;

  }

  p {

    font-size: 1.25em;
  }


}

@media (max-width: 1480px) {
  .menu-font {
    font-family: 'Special Elite', cursive;
    font-size: 1.2em;
    font-weight: 500;
    color: #222;
    text-decoration: underline;
    line-height: .7em;
    padding-top: .8rem;
    text-align: center;
  }

  .menu-size {
    height: 3vh;
    width: 12vw;
    top: -4vh;
    border-radius: 15px 15px 0 0;

  }

  .tab-class {
    top: 15vh;
    margin: 0 2%;
    width: 95%;
    height: 85vh;
    border-radius: 5px;
  }

  .contents {

    font-size: 1em;


  }

  p {

    font-size: 1.25em;
  }


}

@media (max-width: 1200px) {
  .menu-font {
    font-family: 'Special Elite', cursive;
    font-size: 1.2em;
    font-weight: 500;
    color: #222;
    text-decoration: underline;
    padding-top: .8rem;
    line-height: .7em;
    text-align: center;
  }

  .menu-size {
    height: 3vh;
    width: 12vw;
    top: -4vh;
    border-radius: 15px 15px 0 0;

  }

  .tab-class {
    top: 15vh;
    margin: 0 2%;
    width: 95%;
    height: 85vh;
    border-radius: 5px;
  }

  .contents {

    font-size: 1em;


  }

  p {

    font-size: 1.25em;
  }

}

@media (max-width: 768px) {
  .menu-font {
    font-family: 'Special Elite', cursive;
    font-size: 1.2em;
    font-weight: 500;
    color: #222;
    text-decoration: underline;
    padding-top: .8rem;
    line-height: .7em;
    text-align: center;
  }

  .menu-size {
    height: 3vh;
    width: 14vw;
    top: -4vh;
    border-radius: 15px 15px 0 0;

  }

  .tab-class {
    top: 15vh;
    margin: 0 2%;
    width: 95%;
    height: 85vh;
    border-radius: 5px;
  }

  .contents {

    font-size: 1em;


  }

  p {

    font-size: 1.25em;
  }


}

@media (max-width: 500px) {
  .sticky {
     background-size: 100%;
  background-repeat: no-repeat;
  width: 250px;
  height: 500px;
  font-size: 0.6em;
  }

.sticky1 {
  top: 10px;
  left: 0px;
transform: rotate(-5deg);


}


.sticky2 {
  top: 360px;
  left: 10px;
transform: rotate(15deg);

}

.sticky3 {
  top: -10px;
  left: 160px;
transform: rotate(9deg);

}

.sticky4 {
  top: 210px;
  left: 20px;

}

.sticky5 {
  top: 180px;
  left: 160px;
  transform: rotate(-5deg);

}



.cup {
  top: 32%;
  right: 1%;
  background-size: 100%;

  width: 200px;
  height: 200px;





}

  .notebook {
  top: 50%;
  left: 20%;
  background-size: 100%;
  width: 300px;
  height: 600px;

}

.notebook div {
  margin-left: 4em;
  margin-top: 3em;
font-family: 'Gochi Hand', cursive;
font-size: 1em;
color:rgba(9, 37, 131, 0.877)

}

.frame {

  background-repeat: no-repeat;
  width: 100px;
  height: 150px;

  background-image: url('/firas.jpeg');
border-top: 10px solid #eee;
border-bottom: 25px solid #eee;
border-left: 5px solid #eee;
border-right: 5px solid #eee;
  box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.3);

transform: rotate(-15deg);



}


.mac {
  bottom: 2%;
  right: 20%;
  background-size: 100%;
  width: 400px;
  height: 400px;






}

  .card {

    padding: 1em;
    margin: 1em 0;
  }

  .frame {
    top: 25%;
    left: 20%;

    background-size: 10em;
    width: 10em;
    height: 7em;


  }

  .contents .p {
    font-size: 1.2em;
  }

  .menu-font {
    font-family: 'Special Elite', cursive;
    font-size: .9em;
    font-weight: 500;
    color: #222;
    text-decoration: underline;
    padding-top: .8rem;
    line-height: .7em;
    text-align: center;
    transform: rotate(270deg);



  }

  .menu-size {
    height: 3.2vh;
    width: 28vw;
    top: -8.5vh;
    border-radius: 0;

  }

  .tab-class {
    top: 25vh;
    margin: 0 2%;
    width: 95%;
    height: 74vh;
    border-radius: 5px;
  }

  .contents {
    padding: 1em;
    font-size: 1em;
  }

  li {
    font-size: .8em;

  }

  h2 {

    font-size: 1em;
    font-weight: bold;
  }

  p {

    font-size: 1em;
  }

  .title .click-here {
    display: none;
  }

}


@mixin tab($col, $tabPos, $label) {

  &::after {
    @extend .menu-font;
    @extend .menu-size;
    content: $label;
    position: absolute;

    background: $col;
    // border-radius: 20px 20px 20px 0;
    left: $tabPos;

  }
}

$col1: #F3DBB2;
$col2: #CFB2B0;
$col3: #FCC0C5;
$col4: #B7C8C2;
$col5: #CFB9B0;
$col6: #E7DED9;
$col7: #B7CCC2;

* {

  line-height: 1.7em;
}

html,
body {
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  padding: 0;
  background: rgb(51, 51, 51);
}

.tab {
  width: 100%;
  position: absolute;
  box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  &:hover~div {
    transform: translateY(80vh);
  }
}



#tab0 {
  @extend .tab;

  height: 100vh;
  padding: 0;
  background: rgb(51, 51, 51);

}

#tab7 {
  @extend .tab;
  @extend .tab-class;
  background: $col7;
  @include tab($col7, 72%, 'Resume');
}

#tab6 {
  @extend .tab;
  @extend .tab-class;
  background: $col6;
  @include tab($col6, 60%, 'Resume');
}

#tab5 {
  @extend .tab;
  @extend .tab-class;
  background: $col5;
  @include tab($col5, 48%, 'Contact');
}

#tab4 {
  @extend .tab;
  @extend .tab-class;
  background: $col4;
  @include tab($col4, 36%, 'Skills');
}

#tab3 {
  @extend .tab;
  @extend .tab-class;
  background: $col3;
  @include tab($col3, 24%, 'Projects');
}

#tab2 {
  @extend .tab;
  @extend .tab-class;
  background: $col2;
  @include tab($col2, 12%, 'Experiance');
}

#tab1 {
  @extend .tab;
  @extend .tab-class;
  background: $col1;
  @include tab($col1, 0, 'About');

}

// .title h1 {
//   position: absolute;
//   top: 0em;

//   padding: 1rem;
//   font-family: 'Callie Chalk Font', sans-serif;
//   transform: rotate(-8deg);
//   color: rgba(255, 255, 255, 0.864);
// }

.title .name {
  position: absolute;
  top: 0em;
  left: 3em;
  background-size: 12em;
  background-repeat: no-repeat;
  width: 27em;
  height: 15em;
transform: rotate(-8deg);
  background-image: url('/title.png');

}

.title .subtitle {
  position: absolute;
  top: 2em;
  left: 3em;
  background-size: 17em;
  background-repeat: no-repeat;
  width: 27em;
  height: 15em;
transform: rotate(-8deg);
  background-image: url('/subtitle.png');

}




.title .click-here {
    position: absolute;
    top: 1em;
    right: 35%;
    background-size: 6em;
    background-repeat: no-repeat;
    width: 8em;
    height: 4em;
    -webkit-transform: rotate(138deg);
    transform: rotate(138deg);
    background-image: url(/here.png);
    background-size: 5em;

}





